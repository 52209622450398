import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://140a2bc7ce4d8a293ff6b7fbeaf4c6c1@o4508139503681536.ingest.de.sentry.io/4508139506499664'

	// 	tracesSampleRate: 1.0,

	// 	//   // This sets the sample rate to be 10%. You may want this to be 100% while
	// 	//   // in development and sample at a lower rate in production
	// 	replaysSessionSampleRate: 0.1,

	// 	//   // If the entire session is not sampled, use the below sample rate to sample
	// 	//   // sessions when an error occurs.
	// 	replaysOnErrorSampleRate: 1.0,
	// 	// If you don't want to use Session Replay, just remove the line below:
	// 	integrations: [replayIntegration()],
	// 	telemetry: false
});

// // If you have a custom error handler, pass it to `handleErrorWithSentry`
///export const handleError = handleErrorWithSentry();
